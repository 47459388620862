<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{
          backgroundImage: `url(${require('@/assets/images/automatum/mp-header.png')})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          height: '100%' 
        }"
      >
        <b-card-body class="text-center">
          <!--
             <h2 class="text-primary">Let's answer some questions</h2>
             <b-card-text class="mb-2">
               or choose a category to quickly find the help you need
             </b-card-text>
          -->
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
        nav-class="nav-left"
      >
        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, categoryName, index) in marketData"
          :key="categoryName"
          :active="!index"
        >
          <!-- title -->
          <template #title>
            <feather-icon :icon="categoryObj.icon" size="18" class="mr-1" />
            <span class="font-weight-bold">{{ categoryObj.title }}</span>
          </template>

          <question-answer :options="categoryObj" />

          <div class="mt-2" style="display: flex; justify-content: right">
            <b-button
              @click="$router.push('/apps/market-place/intergration')"
              variant="primary"
              style="width: 10rem"
            >
              Start
            </b-button>
          </div>
        </b-tab>
        <!--/ payment tab -->

        <!--/ sitting lady image -->
      </b-tabs>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>You still have a question?</h2>
          <b-card-text class="mb-3">
            If you cannot find a question in our Marketplace, you can always
            contact us. We will answer to you shortly!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-link @click="$router.push('/apps/calendar')">
              <b-avatar size="42" rounded variant="light-primary" class="mb-2">
                <feather-icon icon="CalendarIcon" size="18" />
              </b-avatar>
            </b-link>
            <h4>Book through Calendy</h4>
            <span class="text-body">We are always happy to help!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card class="shadow-none py-1 faq-contact-card">
            <b-link @click="$router.push('/apps/contact')">
              <b-avatar size="42" rounded variant="light-primary" class="mb-2">
                <feather-icon icon="MailIcon" size="18" />
              </b-avatar>
            </b-link>
            <h4>hello@help.com</h4>
            <span class="text-body">Best way to get answer faster!</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BForm,
  BInputGroup,
  BFormInput,
  BCardText,
  BInputGroupPrepend,
  BTabs,
  BTab,
  BImg,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BLink,
} from "bootstrap-vue";
import QuestionAnswer from "./QuestionAnswer.vue";
import { data } from "./market-place-data";
export default {
  components: {
    BForm,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BCardBody,
    BInputGroup,
    BFormInput,
    BCardText,
    BLink,
    BInputGroupPrepend,
    BTabs,
    BTab,
    BImg,
    BButton,
    QuestionAnswer,
  },
  data() {
    return {
      marketSearchQuery: "",
      marketData: {},
    };
  },
  mounted() {
    this.marketData = data.marketData;
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-faq.scss";
</style>
