const data = {
   marketData: {
    saas__contract: {
      icon: 'CreditCardIcon',
      title: 'SaaS Contract',
      subtitle: 'Not sure which listing you need? contact us for a chat',
      qandA: [
        {
          question: 'What is a SaaS contract?',
          ans:
            'With contract pricing model for your product on the AWS Marketplace, your buyer can be billed in advance or on a flexible payment schedule, if you offer that option. This is the easiest way to get your product listed on Marketplace, and will unlock private offers once listed. This implementation will allow your customers information to be captured and emailed to you. You can then use that information to provision the software in the background or get in touch with them to perform further discovery while AWS handles the billing side of things.',
        },
        {
          question: 'Will I own the implementation?',
          ans:
            'Our solution is deployed and configured in your own account, there are no hidden or ongoing fees.',
        },
        {
          question: 'How much does this cost?',
          ans:
            'Please refer to our listing on AWS Marketplace for latest pricing. This listing costs a fix price of $12,000.',
        },
        {
          question: 'Is there any maintenance required?',
          ans:
            'There is no maintenance required for this product as eveyrthing is deployed using serverless technologies. We have been operating for over 10 years and we stand by our platform and products.',
        },
        {
          question: 'How do I know this listing is the right one for me?',
          ans:
            'When it comes to listing your product on Marketplace, you need to make choices based on how you package your product, pricing, and end user delivery method. Using our platform we provide clear insight into AWS Marketplace listing options and help you with the decision making process. You can schedule a call with one of our engineers to walk you through various options and help you choose the billing model that is right for you.',
        },
        {
          question: 'How long does it take to do this implementation?',
          ans:
            'The listing could take anywhere between 1-4 weeks. ',
        },
        {
          question: 'What if I have more than one item to listing?',
          ans:
            'We charge an additional $5,000 per additional listing. ',
        },
      ],
    },
    saas__subscription: {
      icon: '',
      title: 'SaaS Subscription',
      subtitle: 'Which license do I need?',
      qandA: [
        {
          question: 'What is a SaaS Subscription?',
          ans:
            'This listing allows your customers to pay for what they use. This option will allow your customer to be billed dynamically based on various metrics that your application will emit to AWS Marketplace metering service. We will help you build out this capability as a part of implementing this product listing.',
        },
        {
          question: 'Will I own the implementation?',
          ans:
            'Our solution is deployed and configured in your own account, there are no hidden or ongoing fees.',
        },
        {
          question: 'How much does this cost?',
          ans:
            'Please refer to our listing on AWS Marketplace for latest pricing. This listing costs a fix price of $12,000.',
        },
        {
          question: 'Is there any maintenance required?',
          ans:
            'There is no maintenance required for this product as eveyrthing is deployed using serverless technologies. We have been operating for over 10 years and we stand by our platform and products.',
        },
        {
          question: 'How do I know this listing is the right one for me?',
          ans:
            'When it comes to listing your product on Marketplace, you need to make choices based on how you package your product, pricing, and end user delivery method. Using our platform we provide clear insight into AWS Marketplace listing options and help you with the decision making process. You can schedule a call with one of our engineers to walk you through various options and help you choose the billing model that is right for you.',
        },
        {
          question: 'How long does it take to do this implementation?',
          ans:
            'The listing could take anywhere between 1-4 weeks. ',
        },
        {
          question: 'What if I have more than one item to listing?',
          ans:
            'We charge an additional $5,000 per additional listing. ',
        },
      ],
    },
    ami__based__product: {
      icon: '',
      title: 'AMI Based Product',
      subtitle: 'Which license do I need?',
      qandA: [
        {
          question: 'What is AMI based listing?',
          ans:
            'If your product needs to be delivered using AMIs this listing is for you. With this listing you have the option of delivering your product as a single AMI, AMI with AWS CloudFormation templates, or a number of them combined. This type of listing often requires building image baking pipelines to ensure both the application and underlying operating system can be patched periodically. Further integrations are required with the AWS Metering service to allow your customers to be billed according to your specifications. We can provide guidance on the best way to offer your product using this method and help you implement this listing in an automated and hassle free way.',
        },
        {
          question: 'Do I need integrations with licence manager?',
          ans:
            'Depending on your licencing model you might need integrtions with licence manager. Usecases where you offer a pool of licences (floating or depliting) to be used within an organization are often suitable for this. ',
        },
        {
          question: 'Will I own the implementation?',
          ans:
            'Our solution is deployed and configured in your own account, there are no hidden or ongoing fees.',
        },
        {
          question: 'How much does this cost?',
          ans:
            'Please refer to our listing on AWS Marketplace for latest pricing. This listing is offered on time and material basis as it is a custom solution.',
        },
        {
          question: 'Is there any maintenance required?',
          ans:
            'We recommend building pipelines that to automate the patching of your AMIs.',
        },
        {
          question: 'How do I know this listing is the right one for me?',
          ans:
            'When it comes to listing your product on Marketplace, you need to make choices based on how you package your product, pricing, and end user delivery method. Using our platform we provide clear insight into AWS Marketplace listing options and help you with the decision making process. You can schedule a call with one of our engineers to walk you through various options and help you choose the billing model that is right for you.',
        },
        {
          question: 'How long does it take to do this implementation?',
          ans:
            'The listing could take anywhere between 1-6 weeks. ',
        },
      ],
    },
    container__based__product: {
      icon: '',
      title: 'Container Based Product',
      subtitle: 'Which license do I need?',
      qandA: [
        {
          question: 'What is Container Based Product listing?',
          ans:
            'If your product uses Docker containers this listing type is for you. In this listing a set of container images and deployment templates are used to go together for deploying your product. We will help you with setting up the container images, usage instructions, and creating the links for deployment templates. We will help you with setting up a product of this type by helping you through passing the security requirements, access requirements, customer information requirements, product usage requirement, and architecture requirement.',
        },
        {
          question: 'How much does this cost?',
          ans:
            'Please refer to our listing on AWS Marketplace for latest pricing. This listing is offered on time and material basis as it is a custom solution.',
        },
        {
          question: 'Is there any maintenance required?',
          ans:
            'We recommend building pipelines that to automate the patching of your AMIs.',
        },
        {
          question: 'How do I know this listing is the right one for me?',
          ans:
            'When it comes to listing your product on Marketplace, you need to make choices based on how you package your product, pricing, and end user delivery method. Using our platform we provide clear insight into AWS Marketplace listing options and help you with the decision making process. You can schedule a call with one of our engineers to walk you through various options and help you choose the billing model that is right for you.',
        },
        {
          question: 'How long does it take to do this implementation?',
          ans:
            'The listing could take anywhere between 1-6 weeks. ',
        },
      ],
    },
    how__it__works: {
      icon: '',
      title: 'How it works',
      subtitle: 'Which license do I need?',
      qandA: [
        {
          question: 'What are the steps to get my product listed?',
          ans:
            'We will carry out an initial consultation to determine the right listing and pricing model for you. We will then guide you through setting up your account with respect to tax and listing juristictions. Next step is for us to carry out the integrations in your account and deliver you the public listing.',
        },
        {
          question: 'Will I have to have my product ready before being listed?',
          ans:
            'Yes, you need atleast an MVP of the product before you can have a public listing.',
        },
        {
          question: 'How about integrations with my CRM?',
          ans:
            'We support integrations with third-parties however this needs to be scoped and delivered on a usecase by usecase basis. We already have solutions for many of these please enquire to find out more.',
        },
        {
          question: 'How much time does my team need to invest?',
          ans:
            'Around 2 hours of your non-technical team and 1 hour of your technical team is required to complete this implementatian.',
        },
        {
          question: 'Do you offer any on-going support?',
          ans:
            'We provide ongoing support for all our marketplace listings.',
        },
      ],
    },
  },
}
export { data }